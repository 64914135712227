<template>
  <el-card class="box-cards">
    <div class="title">
      <p class="pastitle">{{ $t("lang.forgetpassword") }}</p>
      <div class="line"></div>
    </div>
    <div class="pasmsg">
      <el-form :model="regFrom" :rules="rules" ref="ruleForm">
        <div class="posinput">
          <el-form-item prop="useremail" class="inputcontent">
            <el-input v-model="regFrom.useremail" :placeholder='$t("lang.Pleaseinputemailss")'></el-input>
            <img class="emailimg" :src="require('@/assets/imgs/youxiang.png')" />
          </el-form-item>
          <div class="slider">
            <SliderLogin @loginver="forgetMsg" @isNull="isNulls" :sliderActive="sliderActive" />
          </div>
          <el-form-item prop="verificationval" class="inputcontent veriCode">
            <el-input v-model="regFrom.verificationval" :placeholder='$t("lang.Pleaseentertheverificationcode")'></el-input>
            <div class="sendmsg" @click="sendmsg">
              <p v-show="msgtime">{{ $t("lang.Sendverificationcode") }}</p>
              <p v-show="!msgtime">{{ count }}S</p>
            </div>
          </el-form-item>
          <el-form-item prop="password" class="inputcontent">
            <img :src="require('@/assets/imgs/mima.png')" />
            <el-input v-model="regFrom.password" 
						 :placeholder="$t('lang.Pleaseenternumbers')" :type="pwdInputType" maxlength="16" :readonly='so' @focus="sd"></el-input>
						 <img class="eyes-icon" :src="eyeIcon" @click="handleShowPwd(1)" style="cursor: pointer"/>
          </el-form-item>
          <el-form-item prop="secondpassword" class="inputcontent">
            <img :src="require('@/assets/imgs/mima.png')" />
            <el-input v-model="regFrom.secondpassword" 
						 :placeholder="$t('lang.Pleaseenternumbersb')" :type="pwdInputTypes" maxlength="16" :readonly='so' @focus="sd"></el-input>
						 <img class="eyes-icon" :src="eyeIcons" @click="handleShowPwd(2)" style="cursor: pointer"/>
          </el-form-item>
          <div class="backlogin" @click="handleGoBackLogin">
            {{$t('lang.Returntologin')}}
            <!-- <router-link to="/"></router-link> -->
          </div>
          <div class="login">
            <div @click="changelang('ruleForm')" class="button">
              {{$t('lang.determine')}}
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </el-card>
</template>

<script>
import SliderLogin from "../../../components/slier/SliderLogin";
import * as forgetPas from "@/api/apis";
import JSEncrypt from "jsencrypt";
import { isMobile,isemail } from '@/utils/time'
export default {
  data() {
    var emails = (rule, value, callback) => {
      console.log(value);
      const reg=new RegExp("(^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$)|(^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$)");
      if (value == "" || value == undefined || value == null) {
        callback(new Error(this.$t('lang.Pleaseinputemailss')));
      } else {
        if (!reg.test(value)) {
          callback(new Error(this.$t('lang.Pleaseinputemailssa')));
        } else {
          callback();
        }
      }
    };
    var checkMaxVal = (rule, value, callback) => {
      // if (value == "" || value == undefined || value == null) {
      //   callback(new Error("请输入密码"));
      // } else if (value < 16 && value > 6) {
      //   callback(new Error("请输入6-16位数密码"));
      // } else {
      //   callback();
      // }
      var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      if (!value) {
        callback(new Error( this.$t("lang.Pleaseenteranewpassword")));
      } else if (!reg.test(value)) {
        callback(new Error(this.$t('lang.defPassword')));
      }else{
        callback()
      }
    };
    var conPasVal = (rule, value, callback) => {
      if (value == "" || value == undefined || value == null) {
        callback(new Error(this.$t('lang.Pleaseentertheconfirmationpassword')));
      } else if (this.regFrom.password === value) {
        callback();
      } else {
        callback(new Error(this.$t('lang.Pleaseentertheconfirmationpassworda')));
      }
    };
		let ficationval= (rule, value, callback) => {
		  if (value == "" || value == undefined || value == null) {
		    callback(new Error(this.$t('lang.Pleaseentertheverificationcode')));
		  } else {
		    callback();
		  }
		};
    return {
      msgtime: true,
      count: "",
      timer: null,
      sliderVal: false /* 滑动验证 */,
      rules: {
        useremail: [
          {
            required: true,
            validator: emails,
            trigger: "blur",
          },
        ],
        verificationval: [
          {
            required: true,
            validator: ficationval,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            validator: checkMaxVal,
            trigger: "blur",
          },
        ],
        secondpassword: [
          {
            required: true,
            validator: conPasVal,
            trigger: "blur",
          },
        ],
      },
      sliderActive: false,
      regFrom: {
        useremail: "",
        verificationval: "",
        password: "",
        secondpassword: "",
      },
			so:true,
			eyeIcon:require("@/assets/imgs/close_eyes.png"),
			eyeIcons:require("@/assets/imgs/close_eyes.png"),
			pwdInputType:'password',
			pwdInputTypes:'password',
    };
  },
	created() {
		let sesw=setInterval(()=>{
			if(sessionStorage.getItem('language')){
				clearInterval(sesw)
			  if (sessionStorage.getItem('language') == "En") {
			    this.$i18n.locale = "en";
			  } else {
			    this.$i18n.locale = "cn";
			  }
			}
		},100)
	},
  methods: {
	  sd(){
		this.so=false  
	  },
    handleGoBackLogin(){
      this.$router.push('/')
    },
    isNulls(val) {
      if (val) {
        if (this.regFrom.useremail == "") {
          this.$message.error(this.$t('lang.Pleaseenternumber'));
          this.sliderActive = false;
        } else {
          this.sliderActive = true;
        }
      }
    },
    changelang(ruleForm) {
      // this.$i18n.locale = this.$i18n.locale == "en" ? "cn" : "en";
      let {
        useremail,
        verificationval,
        password,
        secondpassword,
      } = this.regFrom;
      const jse = new JSEncrypt();
      const publiceKey =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDXYI847fATDgZY6gFvGzaR7UXN9c007DEb6M6DvbSE5F3+apLG+PqujLIa8YzvSGntT3YTpN/RNQxo4I7NWIIbHC5u4zobDW1XNJOun5NUgnLglJxI5LrFIo4VzRA8x3TPh4Eb2fuc4eSO3j5XzL2OY8h+H1Npz4JwOdj7Okoc4QIDAQAB";
      jse.setPublicKey(publiceKey);
      let pasVal = {
        email: '',
        randomNun: verificationval,
        password: jse.encrypt(password),
        passwordqr: jse.encrypt(secondpassword),
      };
			if(useremail.indexOf('@')!==-1){
				pasVal.email=useremail
			}else{
				pasVal.email=useremail
			}
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          forgetPas.resetPas(pasVal).then((res) => {
            this.$message.success(this.$t('lang.Operations'));
            this.regFrom.useremail = "";
            this.regFrom.verificationval = "";
            this.regFrom.password = "";
            this.regFrom.secondpassword = "";
            this.sliderVal = false;
            this.$router.push("/");
          });
        } else {
          return false;
        }
      });
    },
    forgetMsg(data) {
      this.sliderVal = data;
      console.log(data);
    },
	//发送验证码
    sendmsg() {
      if (isemail(this.regFrom.useremail) && !this.timer && this.sliderVal) {
        let sendval = {
          email: this.regFrom.useremail,
          code: 2,
        };
        forgetPas.sendMail(sendval).then((res) => {
          let sk=res.msg
          if(res.msg){
          	let wq=sk.split('-')
          	if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
          		sk=wq[0]
          	}else{
          		sk=wq[1]
          	}
          	this.$message.success(sk);
          	if(wq[0].indexOf('操作过于频繁')!=0){
          		this.getCode();
          	}
          }
        });
      }
      if (isMobile(this.regFrom.useremail) && !this.timer && this.sliderVal) {
        let sendval = {
          phone: this.regFrom.useremail,
          code: 5,
        };
        forgetPas.sendPhoneCode(sendval).then((res) => {
          let sk=res.msg
          if(res.msg){
						let wq=sk.split('-')
          	if(sessionStorage.getItem('language')&&sessionStorage.getItem('language')=='zhong'){
          		sk=wq[0]
          	}else{
          		sk=wq[1]
          	}
          	this.$message.success(sk);
						if(wq[0].indexOf('操作过于频繁')!=0){
							this.getCode();
						}
          }
        });
      }
    },
		//显示隐藏密码
		handleShowPwd(ind) {
		  if(ind==1){
				this.pwdInputType = this.pwdInputType === "password" ? "text" : "password";
				this.eyeIcon =
				  this.eyeIcon === require("@/assets/imgs/close_eyes.png")
				    ? require("@/assets/imgs/kejian.png")
				    : require("@/assets/imgs/close_eyes.png");
			}else{
				this.pwdInputTypes = this.pwdInputTypes === "password" ? "text" : "password";
				this.eyeIcons =
				  this.eyeIcons === require("@/assets/imgs/close_eyes.png")
				    ? require("@/assets/imgs/kejian.png")
				    : require("@/assets/imgs/close_eyes.png");
			}
		},
    getCode() {
      /* 倒计时 */
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.msgtime = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.msgtime = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
  },
  components: {
    SliderLogin,
  },
};
</script>
<style lang="less" scpoed>
.box-cards {
  /* 卡片 */
  padding: .2rem .6rem;
  box-sizing: border-box;
  margin-right: 12%;
  position: relative;
}
.title {
  width: 4.6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  p.pastitle {
    font-size: 0.24rem;
    font-family: PingFang-SC-Bold;
    font-weight: bold;
    color: #424242;
  }
  .line {
    width: 0.58rem;
    height: 2px;
    background: #131715;
    margin-bottom: .2rem;
  }
}

.pasmsg {
  width: 4.6rem;
  position: relative;
  /* input输入框 */
  .inputcontent {
    width: 4.6rem;
    height: 0.62rem;
    // margin-bottom: 15px;
    position: relative;
    input {
      outline-style: none;
      width: 4.6rem;
      height: 0.58rem;
      text-decoration: none;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      text-indent: 0.51rem;
      font-size: 0.16rem;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 0.6rem;
    }
    img {
      width: 0.18rem;
      height: 0.2rem;
      position: absolute;
      left: 0.23rem;
      top: 0.2rem;
    }
    .emailimg {
      width: 0.18rem;
      height: 0.14rem;
      left: 0.22rem;
      top: 0.24rem;
    }
		.eyes-icon {
		  width: 0.26rem;
		  height: 0.18rem;
		  position: absolute;
		  top: 0.2rem;
		  left: 90%;
		  right: 0.2rem !important;
		}
  }
}
/* 返回登录 */
.backlogin {
		width: 1.5rem;
    font-size: .14rem;
    font-family: PingFang-SC-Bold;
    font-weight: 500;
    color: #6eb92b;
    cursor: pointer;
}
.login {
  // margin-top: 30px;
  .button {
    width: 4.6rem;
    height: 0.6rem;
    outline-style: none;
    border: none;
    background: linear-gradient(0deg, #333232, #131715);
    border-radius: 6px;
    text-align: center;
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    line-height: 0.6rem;
    margin-top: .2rem;
  }
}
/* 验证码发送 */
.sendmsg {
  width: 1.16rem;
  height: 0.5rem;
  background: #3e3f3e;
  border-radius: 6px;
  position: absolute;
  right: 0.06rem;
  top: 0.05rem;
  z-index: 10;
  p {
    text-align: center;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.5rem;
  }
}
/* 滑块样式 */
.slider {
  width: 4.6rem;
  height: 0.58rem;
  background: #e6e6e6;
  border-radius: 2px;
  margin-bottom: .2rem;
}
/deep/.el-input .el-input__inner {
  width: 100%;
  color: #333 !important;
}
</style>
